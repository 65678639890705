import Api from "@/services";

const createCustomers = async (object) => {
  const response = await Api.post("v1/customer", object);
  return response;
};

const getCustomers = async (query) => {
  try {
    const response = await Api.query("v1/customer", query);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getCustomersById = async (id) => {
  try {
    const response = await Api.get("v1/customer", id);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getAddressByCep = async (cep) => {
  try {
    const response = await Api.get("v1/customer/cep", cep);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const updateCustomers = async (object, id) => {
  try {
    const response = await Api.put("v1/customer", id, object);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const deleteCustomers = async (id) => {
  try {
    const response = await Api.delete("v1/customer", id);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getCustomersByWeek = async () => {
  try {
    const response = await Api.query("v1/customer/customers-by-week");
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  createCustomers,
  getCustomers,
  getCustomersById,
  updateCustomers,
  deleteCustomers,
  getAddressByCep,
  getCustomersByWeek,
};
