<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="fullscreen"
    transition="dialog-bottom-transition"
    :width="width"
    persistent
  >
    <v-card :color="bgcolor">
      <v-toolbar dark color="#35909d">
        <v-btn icon dark :to="{ name: $route.matched[0].name }">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="$emit('handlerSave', true)"
            :disabled="!isValid"
            :loading="isLoading"
          >
            Salvar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-6">
        <slot> </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["isValid", "isLoading", "fullscreen", "title", "width", "bgcolor"],
  data() {
    return {
      dialog: true,
    };
  },
  mounted() {},
};
</script>
