<template>
  <validation-provider v-slot="{ errors }" :name="label" :rules="rules">
    <v-text-field
      v-bind="$attrs"
      v-if="type === 'textField'"
      :label="label"
      :type="typeForm"
      :placeholder="placeholder"
      :rounded="rounded"
      :dense="dense"
      :required="required"
      :error-messages="errors"
      @input="(v) => $emit('input', v)"
    ></v-text-field>
    <v-textarea
      v-bind="$attrs"
      v-if="type === 'textArea'"
      :label="label"
      :placeholder="placeholder"
      v-bind:[fieldLayout]="true"
      :rounded="rounded"
      :dense="dense"
      :required="required"
      :error-messages="errors"
      v-on="$listeners"
      @input="(v) => $emit('input', v)"
    ></v-textarea>
    <v-text-field
      v-bind="$attrs"
      v-if="type === 'time'"
      :label="label"
      v-bind:[fieldLayout]="true"
      :rounded="rounded"
      :dense="dense"
      :required="required"
      :error-messages="errors"
      v-on="$listeners"
      @input="(v) => $emit('input', v)"
    ></v-text-field>
    <v-file-input
      v-if="type === 'fileInput'"
      v-bind="$attrs"
      :deletable-chips="deletableChips"
      :chips="chips"
      :label="label"
      :placeholder="placeholder"
      v-bind:[fieldLayout]="true"
      :rounded="rounded"
      :dense="dense"
      :required="required"
      :error-messages="errors"
      :items="items"
      @input="(v) => $emit('input', v)"
    ></v-file-input>
    <v-autocomplete
      v-if="type === 'autoComplete'"
      v-bind="$attrs"
      :deletable-chips="deletableChips"
      :chips="chips"
      :label="label"
      :placeholder="placeholder"
      :item-value="itemValue"
      :item-text="itemText"
      v-bind:[fieldLayout]="true"
      :rounded="rounded"
      :dense="dense"
      :required="required"
      :error-messages="errors"
      :items="items"
      :multiple="multiple"
      @input="(v) => $emit('input', v)"
    ></v-autocomplete>
    <v-combobox
      v-if="type === 'combobox'"
      v-bind="$attrs"
      :deletable-chips="deletableChips"
      :chips="chips"
      :label="label"
      :placeholder="placeholder"
      v-bind:[fieldLayout]="true"
      :rounded="rounded"
      :dense="dense"
      :required="required"
      :error-messages="errors"
      :items="items"
      :multiple="multiple"
      @input="(v) => $emit('input', v)"
    ></v-combobox>
  </validation-provider>
</template>
<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, setInteractionMode } from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio.",
});

export default {
  components: {
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    fieldLayout: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      default: true,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
      required: true,
    },
    rules: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      default: "textField",
      required: true,
    },
    items: {
      type: Array,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    typeForm: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
    },
    itemText: {
      type: String,
    },
  },
};
</script>
