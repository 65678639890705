import Api from "@/services";

const createTerminal = async (object) => {
  const response = await Api.post("v1/terminals", object);
  return response;
};

const getTerminals = async (query) => {
  try {
    const response = await Api.query("v1/terminals", query);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getTerminalById = async (id, query) => {
  try {
    const response = await Api.get("v1/terminals", id, query);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const updateTerminal = async (object, id) => {
  try {
    const response = await Api.put("v1/terminals", id, object);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const copyTerminal = async (object, id) => {
  try {
    const response = await Api.copy("v1/terminals", id);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const deleteTerminal = async (id) => {
  try {
    const response = await Api.delete("v1/terminals", id);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getTerminalsByWeek = async () => {
  try {
    const response = await Api.query("v1/terminals/terminals-by-week");
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getCities = async () => {
  try {
    const response = await Api.query("v1/terminals/cities");
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getStates = async () => {
  try {
    const response = await Api.query("v1/terminals/states");
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
export default {
  createTerminal,
  getTerminals,
  getTerminalById,
  updateTerminal,
  copyTerminal,
  deleteTerminal,
  getTerminalsByWeek,
  getCities,
  getStates,
};
