<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <BaseDialog
      :isValid="!invalid"
      :isLoading="isLoading"
      title="Adicionar Conteudo"
      @handlerSave="verifyTypeForm"
      width="60%"
    >
      <v-row>
        <v-col cols="8">
          <BaseInputValidator
            type="textField"
            label="Razão Social *"
            clearable
            rounded
            v-model="form.corporateName"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>
        <v-col cols="4">
          <BaseInputValidator
            type="textField"
            label="Nome fantasia *"
            clearable
            rounded
            v-model="form.fantasyName"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <BaseInputValidator
            type="textField"
            label="CNPJ"
            v-mask="'##.###.###/####-##'"
            clearable
            :rules="{ required: true }"
            rounded
            v-model="form.cnpj"
            placeholder="00.000.000/0000-00"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>

        <v-col cols="3">
          <BaseInputValidator
            type="textField"
            label="Atuação"
            clearable
            rounded
            v-model="form.acting"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>

        <v-col cols="5">
          <v-file-Input
            @change="handleFile"
            label="Logo"
            v-model="form.fileName"
            filled
            show-size
            rounded
            dense
            clearable
            accept="image/png, image/jpeg, image/bmp"
            :prepend-icon="null"
            append-icon="mdi-cloud-upload-outline"
            background-color="#F5F8FA"
          ></v-file-Input>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <BaseInputValidator
            type="textField"
            label="CEP"
            v-mask="'########'"
            v-model="form.cep"
            placeholder="00000000"
            clearable
            rounded
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>
        <v-col cols="7">
          <BaseInputValidator
            type="textField"
            label="Endereço"
            clearable
            rounded
            v-model="form.address"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>
        <v-col cols="2">
          <BaseInputValidator
            type="textField"
            label="Número"
            clearable
            rounded
            v-model="form.number"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <BaseInputValidator
            type="textField"
            label="Complemento"
            clearable
            rounded
            v-model="form.complement"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>

        <v-col cols="3">
          <BaseInputValidator
            type="textField"
            label="Bairro"
            clearable
            rounded
            v-model="form.district"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>

        <v-col cols="4">
          <BaseInputValidator
            type="textField"
            label="Cidade"
            clearable
            rounded
            v-model="form.city"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>
        <v-col cols="2">
          <BaseInputValidator
            type="textField"
            label="UF"
            clearable
            rounded
            v-model="form.state"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <BaseInputValidator
            type="textField"
            label="Telefone celular"
            v-mask="'(##) #####-####'"
            v-model="form.cellPhone"
            clearable
            rounded
            placeholder="(00) 00000-0000"
            filled
            dense
            background-color="#F5F8FA"
          ></BaseInputValidator>
        </v-col>
        <v-col cols="7">
          <BaseInputValidator
            type="autoComplete"
            label="Terminais associados"
            fieldLayout="filled"
            rounded
            dense
            itemValue="_id"
            itemText="name"
            multiple
            v-model="form.terminals"
            :items="terminals"
          />
        </v-col>
      </v-row>
    </BaseDialog>
  </validation-observer>
</template>

<script>
import BaseInputValidator from "../../components/UI/BaseInputValidator";
import { ValidationObserver } from "vee-validate";
import CustomerService from "./service";
import BaseDialog from "../../components/UI/BaseDialog";
import terminalService from "../terminal/service";

export default {
  name: "CreateCustomer",
  data() {
    return {
      isLoading: false,
      form: {
        corporateName: "",
        fantasyName: "",
        cnpj: "",
        acting: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        cep: "",
        state: "",
        contactName: "",
        email: "",
        phone: "",
        cellPhone: "",
        logo: "",
        fileName: "",
        terminals: "",
      },
      dialog: true,
      terminals: [],
    };
  },
  methods: {
    async getTerminals() {
      try {
        const terminals = await terminalService.getTerminals({ pagination: false });
        this.terminals = terminals.data.docs;
      } catch (error) {
        console.error(error);
      }
    },
    async sendCustomersInfo() {
      try {
        const customers = await CustomerService.createCustomers(this.form);
        return customers;
      } catch (error) {
        console.error(error);
      }
    },
    async verifyTypeForm() {
      if (this.typeForm === "EditCustomer") {
        const customers = await this.updateCustomers();
        this.$router.push({ name: "Customers" });
        this.$emit("created", {
          customers: customers,
          typeForm: this.typeForm,
        });
      } else {
        const customers = await this.sendCustomersInfo();
        this.$router.push({ name: "Customers" });
        this.$emit("created", {
          customers: customers,
          typeForm: this.typeForm,
        });
      }
    },
    async updateCustomers() {
      try {
        const customers = await CustomerService.updateCustomers(
          this.form,
          this.$route.params.id
        );
        return customers;
      } catch (error) {
        console.error(error);
      }
    },
    async handleFile(e) {
      this.createBase64File(e);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.logo = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
  },
  async mounted() {
    this.getTerminals();
    this.typeForm = this.$route.name;
    if (this.typeForm === "EditCustomer") {
      const customers = await CustomerService.getCustomersById(
        this.$route.params.id
      );
      this.form = customers.data;
    }
  },
  components: {
    BaseInputValidator,
    ValidationObserver,
    BaseDialog,
  },

  watch: {
    "form.cep": {
      handler: async function (val) {
        if (val.length === 8) {
          const cep = await CustomerService.getAddressByCep(val);
          this.form.district = cep.bairro;
          this.form.complement = cep.complemento;
          this.form.city = cep.localidade;
          this.form.address = cep.logradouro;
          this.form.state = cep.uf;
        }
      },
      deep: true,
    },
  },
};
</script>
